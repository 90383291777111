<script lang="ts" setup>
defineProps<{
  class?: string;
}>();
const activityStore = useActivityStore();
const activeEventId = ref<string | undefined>(undefined);
</script>

<template>
  <div :class="cn('flex flex-col gap-3 scroll-area p-3', $props.class)">
    <InfiniteScroller
      :loading="activityStore.isLoadingNextPage"
      :elementsLength="activityStore.events.length"
      @loadMore="activityStore.loadNextPage"
    >
      <ul v-auto-animate class="flex flex-col gap-2.5">
        <ActivityCard
          v-for="event in activityStore.events"
          :key="event.id"
          :event="event"
          :active="activeEventId === event.id"
          @click="activeEventId = event.id"
        />
      </ul>
    </InfiniteScroller>
  </div>
</template>
