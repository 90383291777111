<script lang="ts" setup>
import type { EventFieldsFragment } from "#gql";

const { event, active } = defineProps<{
  class?: string;
  event: EventFieldsFragment;
  active: boolean;
}>();

const type = computed(() => {
  if (!event.claim) return "user";
  switch (event.claim?.status) {
    case "submitted":
    case "observed":
    case "discarded":
    case "spam":
    case "rejected":
      return "submission";
    default:
      return "claim";
  }
});

const to = computed(() => {
  if (type.value === "user") return undefined;
  return `/activity/${type.value}s/${event.claim?.id}`;
});

const fact = computed(() => {
  if (!event.claim) return "";
  if (type.value === "claim") return `Faktencheck #${event.claim?.processId}`;
  return `Einreichung ${event.claim?.shortId}`;
});

const formatDate = useFormatDate();
const date = formatDate({
  date: event.createdAt,
  relative: true
});

const { t } = useI18n();

const text = computed(() => {
  if (event.action === "UPDATE") {
    if (event.tableName === "claim") {
      if (!event.content) return;
      const fieldName = Object.keys(event.content)?.[0];
      const fieldNamePretty = t("claim." + fieldName?.replace("label_name", "label").replaceAll("_", "."));
      if (!fieldNamePretty) return;
      if (fieldName === "status") {
        return `hat den Status »${t("status." + event.content?.status)}« gesetzt.`;
      }
      return `hat das Feld »${fieldNamePretty}« bearbeitet:`;
    }
    if (event.tableName === "origin") {
      if (!event.content) return;
      const fieldName = t("source." + Object.keys(event.content)?.[0]);
      return `hat im Ursprung ${event.metadata?.index} das Feld »${fieldName}« bearbeitet:`;
    }
    if (event.tableName === "fact") {
      return `hat im Fakt ${event.metadata?.index} den Text bearbeitet:`;
    }
    if (event.tableName === "source") {
      return `hat im Fakt ${event.metadata?.fact_index} den Beleg ${event.metadata?.index}} bearbeitet:`;
    }
    if (event.tableName === "user") {
      return `hat ihr/sein Profil bearbeitet.`;
    }
  }
  if (event.action === "INSERT") {
    if (event.tableName === "claim") {
      return `hat die Einreichung ${event.claim?.shortId} hinzugefügt.`;
    }
    if (event.tableName === "origin") {
      return `hat den Ursprung ${event.metadata?.index} hinzugefügt:`;
    }
    if (event.tableName === "fact") {
      return `hat den Fakt ${event.metadata?.index} hinzugefügt:`;
    }
    if (event.tableName === "source") {
      return `hat im Fakt ${event.metadata?.fact_index} den Beleg ${event.metadata?.index} hinzugefügt.`;
    }
  }
  if (event.action === "DELETE") {
    if (event.tableName === "claim") {
      return `hat den Ursprung ${event.metadata?.index} entfernt.`;
    }
    if (event.tableName === "fact") {
      return `hat den Fakt ${event.metadata?.index} entfernt.`;
    }
    if (event.tableName === "source") {
      return `hat im Fakt ${event.metadata?.fact_index} den Beleg ${event.metadata?.index} entfernt.`;
    }
    if (event.tableName === "origin") {
      return `hat den Ursprung ${event.metadata?.index} entfernt.`;
    }
  }
  return "";
});

const payload = computed(() => {
  if (event.tableName === "user") return undefined;
  if (!event.content) return undefined;
  if (event.action === "INSERT") {
    if (event.tableName === "claim") return;
    return Object.values(event.content)?.[0];
  }
  if (event.action === "UPDATE") {
    if (event.tableName === "claim") {
      if (!event.content) return;
      const fieldName = Object.keys(event.content)?.[0];
      if (fieldName === "status") return;
    }
    return Object.values(event.content)?.[0];
  }
  return undefined;
});

const username = computed(() => {
  return event.user?.username || event.metadata?.username;
});

const claimStore = useClaimStore();

const emits = defineEmits<{
  (event: "click"): void;
}>();

const handleClick = () => {
  emits("click");
  if (event.tableName === "origin" || event.tableName === "source") {
    claimStore.addClaim(event.claim?.id);
    claimStore.claims[event.claim?.id].sourceIds = [event.entryId];
    claimStore.claims[event.claim?.id].focusedElement = event.entryId;
  }
  if (event.tableName === "fact") {
    claimStore.addClaim(event.claim?.id);
    claimStore.claims[event.claim?.id].focusedElement = event.entryId;
  }
  if (event.tableName === "claim") {
    claimStore.addClaim(event.claim?.id);
    if (!event.content) return;
    const fieldname = Object.keys(event.content)?.[0];
    claimStore.claims[event.claim?.id].focusedElement = fieldname;
  }
};
</script>

<template>
  <BasicCard
    :to
    :class="cn('p-3 focus:border-online', { 'border-online hover:border-online': active }, $props.class)"
    @click="handleClick"
  >
    <p class="flex justify-between">
      <span class="small">{{ fact }}</span>
      <time class="small">{{ date }}</time>
    </p>
    <div class="flex gap-2.5">
      <Avatar v-if="username" :username />
      <div class="flex flex-col gap-1 overflow-hidden">
        <p>{{ username }} {{ text }}</p>
        <p v-if="payload" class="text-sm overflow-hidden text-ellipsis whitespace-nowrap">
          {{ payload }}
        </p>
      </div>
    </div>
  </BasicCard>
</template>
